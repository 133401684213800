<!--
 * @Author: yangliao
 * @Date: 2020-11-27 16:46:23
 * @LastEditTime: 2020-12-29 14:41:21
 * @LastEditors: Please set LastEditors
 * @Description: 客户信息
 * @FilePath: /netHallOfficialAccounts/src/views/Business/components/CustomersNews.vue
-->
<template>
  <div>
    <!-- v-if="customersNews.handUserVO && Object.keys(customersNews.handUserVO).length > 0" -->
    <!-- 固定回显户号+地址 -->
    <!-- :input-align="realAlign" -->
    <!-- <div
      class="vessel"
      v-if="customersNews.handUserVO && Object.keys(customersNews.handUserVO).length > 0"
    >
      <van-field readonly label="户号" input-align="right">
        <template #input>
          <span>{{ customersNews.handUserVO.wuserNo }}</span>
        </template>
      </van-field>
      <van-field readonly label="地址" input-align="right">
        <template #input>
          <span>{{ customersNews.handUserVO.wuserAddress }}</span>
        </template>
      </van-field>
    </div> -->

    <div class="vessel">
      <van-field readonly label="户号" input-align="right">
        <template #input>
          <span>{{ customersNews.waterUserVO && customersNews.waterUserVO.wuserNo }}</span>
        </template>
      </van-field>
      <van-field readonly label="地址" input-align="right">
        <template #input>
          <span>{{ customersNews.waterUserVO && customersNews.waterUserVO.wuserAddress }}</span>
        </template>
      </van-field>
    </div>

    <!-- 是户主，只显示户主 -->
    <div class="vessel" v-if="stateBoolean">
      <van-field readonly label="户主" input-align="right">
        <template #input>
          <span>{{ customersNews.waterUserVO && customersNews.waterUserVO.wuserName }}</span>
        </template>
      </van-field>
      <van-field readonly label="手机号" input-align="right">
        <template #input>
          <span>{{ customersNews.waterUserVO && customersNews.waterUserVO.phone }}</span>
        </template>
      </van-field>
      <van-field readonly label="身份证" input-align="right">
        <template #input>
          <span>{{ customersNews.waterUserVO && customersNews.waterUserVO.idNo }}</span>
        </template>
      </van-field>
    </div>
    <!-- 不是户主，回显户主 + 办理人 -->
    <div v-else>
      <div class="vessel">
        <van-field readonly label="户主" input-align="right">
          <template #input>
            <span>{{ customersNews.waterUserVO && customersNews.waterUserVO.wuserName }}</span>
          </template>
        </van-field>
        <van-field readonly label="手机号" input-align="right">
          <template #input>
            <span>{{ customersNews.waterUserVO && customersNews.waterUserVO.phone }}</span>
          </template>
        </van-field>
        <van-field readonly label="身份证" input-align="right">
          <template #input>
            <span>{{ customersNews.waterUserVO && customersNews.waterUserVO.idNo }}</span>
          </template>
        </van-field>
      </div>

      <div class="vessel" v-if="needHandUser">
        <van-field readonly label="办理人" input-align="right">
          <template #input>
            <span>{{ customersNews.handUserVO && customersNews.handUserVO.wuserName }}</span>
          </template>
        </van-field>
        <van-field readonly label="手机号" input-align="right">
          <template #input>
            <span>{{ customersNews.handUserVO && customersNews.handUserVO.phone }}</span>
          </template>
        </van-field>
        <van-field readonly label="身份证" input-align="right">
          <template #input>
            <span>{{ customersNews.handUserVO && customersNews.handUserVO.idNo }}</span>
          </template>
        </van-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'CustomersNews',
  data() {
    return {};
  },
  props: {
    customersNews: {
      type: Object,
      default: () => {
        return null;
      },
    },
    businessState: {
      type: String,
      default: 'add',
    },
    stateBoolean: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    needHandUser: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  computed: {
    realAlign() {
      if (this.businessState !== 'see') {
        return 'left';
      }
      return 'right';
    },
  },
  mounted() {
    console.log('customersNews---', this.customersNews);
  },
  methods: {
    /* 返回组件数据 */
    customersData() {
      // 阶梯人口不显示办理人，其他显示
      let data = {
        handUserVO: {
          wuserName: this.customersNews.handUserVO && this.customersNews.handUserVO.wuserName,
          phone: this.customersNews.handUserVO && this.customersNews.handUserVO.phone,
          idNo: this.customersNews.handUserVO && this.customersNews.handUserVO.idNo,
          wuserNo: this.customersNews.handUserVO && this.customersNews.handUserVO.wuserNo,
          wuserAddress: this.customersNews.handUserVO && this.customersNews.handUserVO.wuserAddress,
        },
        waterUserVO: {
          wuserName: this.customersNews.waterUserVO && this.customersNews.waterUserVO.wuserName,
          phone: this.customersNews.waterUserVO && this.customersNews.waterUserVO.phone,
          idNo: this.customersNews.waterUserVO && this.customersNews.waterUserVO.idNo,
          wuserNo: this.customersNews.waterUserVO && this.customersNews.waterUserVO.wuserNo,
          wuserAddress:
            this.customersNews.waterUserVO && this.customersNews.waterUserVO.wuserAddress,
        },
        telephone:
          (this.customersNews.handUserVO && this.customersNews.handUserVO.phone) ||
          (this.customersNews.waterUserVO && this.customersNews.waterUserVO.phone),
        contacts:
          (this.customersNews.handUserVO && this.customersNews.handUserVO.wuserName) ||
          (this.customersNews.waterUserVO && this.customersNews.waterUserVO.wuserName),
        accountNo:
          (this.customersNews.handUserVO && this.customersNews.handUserVO.wuserNo) ||
          (this.customersNews.waterUserVO && this.customersNews.waterUserVO.wuserNo),
        address: {
          areaInfor:
            (this.customersNews.handUserVO && this.customersNews.handUserVO.wuserAddress) ||
            (this.customersNews.waterUserVO && this.customersNews.waterUserVO.wuserAddress),
          fullAddressInfor: '',
        },
      };
      return data;
    },
  },
  watch: {
    customersNews: {
      immediate: true,
      handler(val) {
        this.customersNews = val;
        console.log('customersNews---', this.customersNews);
      },
    },
    stateBoolean: {
      immediate: true,
      handler(val) {
        this.stateBoolean = val;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.vessel {
  margin: 16px;
  border-radius: 6px;
  overflow: hidden;
}
</style>
